import React from "react";
import { proofRequestVIP, mintFromWebVIP } from "../web3/web_functions";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import mintExampleAbi from "../mintExampleAbi.json";
import styles from "./VIPSale.module.css"

const mintExampleAddress = "0xb0605365874975650551624eFEA227cfeA29585a";

function VIPSale({
  accounts,
  setLoading,
  setTransaction,
  setMintButtonPressed,
}) {
  const [proofVIP, setProofVIP] = useState("");
  const [vipSaleActive, setvipSaleActive] = useState(false);
  const [vipListMember, setVipListMember] = useState(false);

  function isVIPSaleActive() {
    if (accounts.length === 14) {
      return <button className={styles.mintButton} disabled={true}>Connect to Metamask first</button>;
    }
    if (!vipSaleActive) {
      return <button className={styles.mintButton} disabled={true}>VIP Sale ist noch nicht aktiv</button>;
    } else {
      return showVIPMint();
    }
  }

  function showVIPMint() {
    if (vipListMember) {
      return <button className={styles.mintButton} onClick={() => handleMintVIP()}>Mint VIP</button>;
    } else {
      return <button className={styles.mintButton} disabled={true}> Du bist kein VIP </button>;
    }
  }
  async function handleMintVIP() {
    try {
      // const connected = isWalletConnected(state);

      // Check if wallet is connected
      if (accounts === "Connect to Metamask") {
        console.log("You need to connect your wallet to continue!");
        return;
      }

      // Callback if transaction was submitted
      const transactionCallback = (receipt) => {
        // const tx_link = "https://rinkeby.etherscan.io/tx/"+ receipt

        setMintButtonPressed(true);
        setLoading(true);
        console.log("Starte Transaktion ->", receipt);
      };

      // Callback if transaction was confirmed
      const receiptCallback = (transactionHash) => {
        const tx_link =
          "https://rinkeby.etherscan.io/tx/" + transactionHash.transactionHash;
        setLoading(false);
        setTransaction(tx_link);
        console.log("Transaktion was send ->", transactionHash);
      };

      // Mint
      await mintFromWebVIP(proofVIP, transactionCallback, receiptCallback);
    } catch (error) {
      console.error(error);

      console.log(error.message);
    }
  }

  async function getVIPSaleActive() {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const contract = new ethers.Contract(
          mintExampleAddress,
          mintExampleAbi.abi,
          provider
        );
        const getVipActive = await contract.vipActive();
        setvipSaleActive(getVipActive);
        if (getVipActive) {
          let { proofDataVIP, inVIPMerkleTree } = await proofRequestVIP(
            address
          );
          // console.log("VIP -> ", proofDataVIP, inVIPMerkleTree);
          setVipListMember(inVIPMerkleTree);
          setProofVIP(proofDataVIP);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // connectAccounts();
    getVIPSaleActive();
  }, [accounts]);

  return <div  >{isVIPSaleActive()}</div>;
}

export default VIPSale;
