import { React, useState } from "react";
import axios from "axios";
import ConnectWallet from "./components/ConnectWallet";
import TotalSupply from "./components/TotalSupply";
import VIPSale from "./components/VIPSale"
import Transaktion from "./components/Transaktion";
import WhitelistSale from "./components/WhitelistSale";


// const mintExampleAddress = "0xFf848302B4E4b966C5Ba43014e17487887c5613F";
axios.defaults.baseURL =
  "https://us-central1-testreactapp-345114.cloudfunctions.net/";

export default function App() {
  const [accounts, setAccounts] = useState("Connect to Metamask");
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState("");
  const [mintButtonPressed, setMintButtonPressed] = useState(false);

  return (
    <div className="container">
      
      <ConnectWallet message={accounts} setMessage={setAccounts} />
      <VIPSale accounts={accounts} setLoading={setLoading} setTransaction={setTransaction} setMintButtonPressed={setMintButtonPressed}/>
      <WhitelistSale accounts={accounts} setLoading={setLoading} setTransaction={setTransaction} setMintButtonPressed={setMintButtonPressed}/>
      <TotalSupply />
      <Transaktion mintButtonPressed={mintButtonPressed} loading={loading} transaction={transaction}/> 
    </div>
  );
}
