import React from "react";
import {
    proofRequestWhitelist,
    mintFromWebWhitelist,
    getAccountWithBalance,
    getMintPrice,
    getWeb3Provider,
  } from "../web3/web_functions";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import mintExampleAbi from "../mintExampleAbi.json";
import styles from "./WhitelistSale.module.css";

const mintExampleAddress = "0xb0605365874975650551624eFEA227cfeA29585a";

function WhitelistSale({
  accounts,
  setLoading,
  setTransaction,
  setMintButtonPressed,
}) {
  const [proofWhitelist, setProofWhitelist] = useState("");
  const [wlSaleActive, setWLSaleActive] = useState(false);
  const [whiteListMember, setwhiteListMember] = useState(false);

  function isWLSaleActive() {
    if (accounts.length === 14) {
      return <button className={styles.mintButton} disabled={true} >Connect to Metamask first</button>;
    }
    if (!wlSaleActive) {
      return <button className={styles.mintButton} disabled={true}>Whitelist Sale ist noch nicht aktiv</button>;
    } else {
      return showWhitelistMint();
    }
  }

  function showWhitelistMint() {
    if (whiteListMember) {
      return <button className={styles.mintButton} disabled={false} onClick={() => handleMintWhitelist()}>Mint Whitelist</button>;
    } else {
      return <button className={styles.mintButton} disabled={true} > Du bist nicht auf Whitelist </button>;
    }
  }
  async function handleMintWhitelist() {
    try {
      // const connected = isWalletConnected(state);

      // Check if wallet is connected
      if (accounts === "Connect to Metamask") {
        console.log("You need to connect your wallet to continue!");
        return;
      }
      let provider = await getWeb3Provider();
      const { balance } = await getAccountWithBalance(provider);

      if (balance < getMintPrice()) {
        console.log("Not enough ether on wallet!");
        return;
      }
      // Callback if transaction was submitted
      const transactionCallback = (receipt) => {
        // const tx_link = "https://rinkeby.etherscan.io/tx/"+ receipt

        setMintButtonPressed(true);
        setLoading(true);
        console.log("Starte Transaktion ->", receipt);
      };

      // Callback if transaction was confirmed
      const receiptCallback = (transactionHash) => {
        const tx_link =
          "https://rinkeby.etherscan.io/tx/" + transactionHash.transactionHash;
        setLoading(false);
        setTransaction(tx_link);
        console.log("Transaktion was send ->", transactionHash);
      };

      // Mint
      await mintFromWebWhitelist(
        proofWhitelist,
        transactionCallback,
        receiptCallback
      );
    } catch (error) {
      console.error(error);

      console.log(error.message);
    }
  }

  async function getWhitelistSaleActive() {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const contract = new ethers.Contract(
          mintExampleAddress,
          mintExampleAbi.abi,
          provider
        );
        const getWLActive = await contract.whiteListActive();
        setWLSaleActive(getWLActive);
        if (getWLActive) {
          let { proofDataWhitelist, inWhitelistMerkleTree } =
            await proofRequestWhitelist(address);
          setwhiteListMember(inWhitelistMerkleTree);
          setProofWhitelist(proofDataWhitelist);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getWhitelistSaleActive();
  }, [accounts]);

  return <div className="">{isWLSaleActive()}</div>;
}

export default WhitelistSale;
