// {String(info.account).substring(0, 6) + "..." + String(info.account).substring(38)}{" "}

import React from "react";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import styles from "./ConnectWallet.module.css";

function ConnectWallet({ message, setMessage }) {
  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);

  function hasEthereum() {
    return (
      typeof window !== "undefined" && typeof window.ethereum !== "undefined"
    );
  }

  // Request wallet account
  async function requestAccount() {
    await window.ethereum.request({ method: "eth_requestAccounts" });
  }

  useEffect(function () {
    async function fetchConnectedAccount() {
      if (!hasEthereum()) {
        setMessage("Install MetaMask");
        setLoading(false);
        return;
      }

      await setConnectedAccount();

      setLoading(false);
    }
    fetchConnectedAccount();
  });

  // Request connection to wallet
  async function requestConnection() {
    try {
      await requestAccount();
    } catch (error) {
      if (error.message) setMessage(error.message);
    }
  }

  // Set address of connected wallet
  async function setConnectedAccount() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();

      if (address) {
        setConnected(true);
        setMessage(address);
      }
    } catch {
      setMessage("Connect wallet");
    }
  }

  // Handle connect wallet click
  async function handleConnectWallet() {
    setLoading(true);

    await requestConnection();
    await setConnectedAccount();

    setLoading(false);
  }
  // Account changes
  useEffect(function () {
    async function listenMMAccount() {
      if (!hasEthereum()) return;
      window.ethereum.on("accountsChanged", async function (accounts) {
        if (accounts && accounts[0]) {
          setMessage(accounts[0]);
        } else {
          setConnected(false);
          setMessage("Connect wallet");
        }
      });
    }

    listenMMAccount();
  });

  return (
    <div>
    <button
      onClick={handleConnectWallet}
      className={styles.connectWallet}
      disabled={connected || message === "Install MetaMask"}
    >
      {!loading ? <>{message}</> : <span>Loading...</span>}
    </button>
    </div>
  );
}

export default ConnectWallet;
