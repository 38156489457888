import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
// import { hasEthereum, requestAccount } from '../utils/ethereum'
// import Minter from '../src/artifacts/contracts/Minter.sol/Minter.json'
import mintExampleAbi from "../mintExampleAbi.json";
import styles from "./TotalSupply.module.css"

const mintExampleAddress = "0xb0605365874975650551624eFEA227cfeA29585a";
const MINT_PRICE = 0.05



export default function TotalSupply() {
    // UI state
    const [loading, setLoading] = useState(true)
    const [totalMinted, setTotalMinted] = useState(0)
    // const [totalValue, setTotalValue] = useState(0)

    // Constants
    const TOTAL = 8888;

    
    function hasEthereum() {
        return (
        typeof window !== "undefined" && typeof window.ethereum !== "undefined"
        );
    }

    // Request wallet account
    async function requestAccount() {
        await window.ethereum.request({ method: "eth_requestAccounts" });
    }

    useEffect( function() {
        async function fetchTotals() {
            if(! hasEthereum()) {
                console.log('Install MetaMask')
                setLoading(false)
                return
            }
    
            await getTotalSupply()
            // await getTotalValue()
        
            setLoading(false)
        }
        fetchTotals();
    });

    // Get total supply of tokens from smart contract
    async function getTotalSupply() {
        try {
          // Interact with contract
          const provider = new ethers.providers.Web3Provider(window.ethereum)
          const contract = new ethers.Contract(mintExampleAddress, mintExampleAbi.abi, provider)
          const data = await contract.totalSupply()
      
          setTotalMinted(data.toNumber());
        } catch(error) {
            console.log(error)
        }
    }

    //  // Get total value collected by the smart contract
    //  async function getTotalValue() {
    //     try {
    //       // Interact with contract
    //       const provider = new ethers.providers.Web3Provider(window.ethereum)
    //       const contract = new ethers.Contract(mintExampleAddress, mintExampleAbi.abi, provider)
    //       const data = await contract.getBalance()
      
    //       setTotalValue(ethers.utils.formatEther(data).toString());
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }

    return (
        <>
            <p className={styles.totalSupplyText}>
                Tokens minted: { loading ? 'Loading...' : `${totalMinted}/${TOTAL}` }
                {/* Contract value: { loading ? 'Loading...' : `${totalValue}ETH` } */}
            </p>
        </>
    )
}