import React from "react";

function Transaktion({ mintButtonPressed, loading, transaction }) {

  return (
    <>
      {mintButtonPressed ? (
        loading ? (
          <div>
            <img
              className="loader"
              src="https://c.tenor.com/5o2p0tH5LFQAAAAi/hug.gif"
              alt="test"
            />
          </div>
        ) : (
          <div>
            <a className="" href={transaction}>
              Link zur Transaktion
            </a>
          </div>
        )
      ) : null}
    </>
  );
}

export default Transaktion;
