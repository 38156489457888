import mintExampleAbi from "../mintExampleAbi.json";
import { ethers } from "ethers";
// import { React, useEffect, useState } from "react";
import axios from "axios";

const mintExampleAddress = "0xb0605365874975650551624eFEA227cfeA29585a";
const MINT_PRICE = 0.05

const getWeb3Provider = async () => {
  const ethereum = window.ethereum;
  const web3 = window.web3;

  let provider;

  if (ethereum) {
    provider = ethereum;
  } else if (web3) {
    provider = web3.currentProvider;
  }

  if (!provider) {
    throw Error("No wallet provider found");
  }

  return new ethers.providers.Web3Provider(provider);
};

const getAccountWithBalance = async (provider) => {
  try {
    const [account] = await provider.send("eth_requestAccounts", []);
    const balance = await provider.getBalance(account);

    return {
      account,
      balance: ethers.utils.formatEther(balance),
    };
  } catch (error) {
    throw Error("Couldn't get account with balance!");
  }
};

const mintFromWebVIP = async (
  proofData,
  transactionCallback,
  confirmationCallback
) => {
  // Get wallet provider
  let provider = await getWeb3Provider();

  // Get account
  //   const { account } = await getAccountWithBalance(provider);

  // Contract
  const contract = new ethers.Contract(
    mintExampleAddress,
    mintExampleAbi.abi,
    provider.getSigner()
  );

  try {
    // Mint
    // const mintResult = await contract.mintTokenFromWeb(quantity, proof, {
    //     value: ethers.utils.parseEther((MINT_PRICE * quantity).toString())
    // });
    const response = await contract.vipMint(proofData);

    // Call confirmation callback after 1 confirmations
    response
      .wait(1)
      .then((receipt) => {
        confirmationCallback(receipt);
      })
      .catch((error) => {
        throw error;
      });

    if (response.hash) {
      transactionCallback(response.hash);
    }
  } catch (error) {
    if (error.code === 4001) {
      throw Error("You denied the transaction!");
    }

    // const errorString = JSON.stringify(error);

    // if (errorString.includes("The sale is not yet open")) {
    //   throw Error("The sale is not yet open");
    // }

    // if (errorString.includes("Your address is not whitelisted")) {
    //   throw Error("Your address is not whitelisted");
    // }

    throw Error("Couldn't mint token!");
  }
};
const mintFromWebWhitelist = async (
  proofData,
  transactionCallback,
  confirmationCallback
) => {
  // Get wallet provider
  let provider = await getWeb3Provider();

  // Get account
  //   const { account } = await getAccountWithBalance(provider);

  // Contract
  const contract = new ethers.Contract(
    mintExampleAddress,
    mintExampleAbi.abi,
    provider.getSigner()
  );

  try {
    // Mint
    // const mintResult = await contract.mintTokenFromWeb(quantity, proof, {
    //     value: ethers.utils.parseEther((MINT_PRICE * quantity).toString())
    // });
    const response = await contract.whitelistMint(proofData, {
            value: ethers.utils.parseEther((MINT_PRICE).toString())
        });

    // Call confirmation callback after 1 confirmations
    response
      .wait(1)
      .then((receipt) => {
        confirmationCallback(receipt);
      })
      .catch((error) => {
        throw error;
      });

    if (response.hash) {
      transactionCallback(response.hash);
    }
  } catch (error) {
    if (error.code === 4001) {
      throw Error("You denied the transaction!");
    }

    // const errorString = JSON.stringify(error);

    // if (errorString.includes("The sale is not yet open")) {
    //   throw Error("The sale is not yet open");
    // }

    // if (errorString.includes("Your address is not whitelisted")) {
    //   throw Error("Your address is not whitelisted");
    // }

    throw Error("Couldn't mint token!");
  }
};

// async function getSaleActive() {
//   if (window.ethereum) {
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const contract = new ethers.Contract(
//       mintExampleAddress,
//       mintExampleAbi.abi,
//       provider
//     );
//     const tx_request = await contract.vipActive();
//     setvipSaleActive(tx_request);
//   }
// }

/**
 * @param address Ethereum address
 * @returns Merkle proof array
 */
const proofRequestWhitelist = async (address) => {
  try {
    let inMerkleTree = true;
    const response = await axios.post("/whitelist", { address: address });

    if (!response.data) throw Error("API content didn't contain proof data.");
    if (response.data.length === 0) inMerkleTree = false;
    return {
      proofDataWhitelist: response.data,
      inWhitelistMerkleTree: inMerkleTree,
    };
  } catch (error) {
    throw Error("Couldn't get proof from API!");
  }
};
/**
 * @param address Ethereum address
 * @returns Merkle proof array
 */
const proofRequestVIP = async (address) => {
  try {
    let inMerkleTree = true;
    const response = await axios.post("/merkletree", { address: address });

    if (!response.data) throw Error("API content didn't contain proof data.");
    if (response.data.length === 0) inMerkleTree = false;
    return { proofDataVIP: response.data, inVIPMerkleTree: inMerkleTree };
  } catch (error) {
    throw Error("Couldn't get proof from API!");
  }
};

// async function reqWhiteList (address) {
//     const data = { address: address };
//     const result = await fetch(
//         "https://europe-west3-brave-inn-342809.cloudfunctions.net/isWhitelist",
//         {
//           method: "POST", // or 'PUT'
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(data),
//         }
//       )
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.length > 0) {
//             console.log(data);
//             setwhiteListMember(true);
//             setProof(data);
//           }
//           return data;
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//         });
// }

// async function getAccount(){
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const signer = provider.getSigner();

//     const ethereum = window.ethereum;
//     const web3 = window.web3;

//     let provider;

//     if (ethereum) {
//         provider = ethereum;
//     } else if (web3) {
//         provider = web3.currentProvider;
//     }

//     if (!provider) {
//         throw Error("No wallet provider found");
//     }

//     return new ethers.providers.Web3Provider(provider);
// }

// async function getContract(provider) {
//     const signer = provider.getSigner();
//     const contract = new ethers.Contract(
//       mintExampleAddress,
//       mintExampleAbi.abi,
//       signer
//     );
//     return contract

// }

const getMintPrice = () => {
    return MINT_PRICE;
}

export { proofRequestWhitelist, proofRequestVIP, mintFromWebVIP, mintFromWebWhitelist, getAccountWithBalance, getMintPrice,getWeb3Provider };
